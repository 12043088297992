'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _vue = require('vue');

var _vue2 = _interopRequireDefault(_vue);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  data: function data() {
    return {
      post: {
        title: {
          rendered: ''
        },
        content: {
          rendered: ''
        },
        date: ''
      }
    };
  },

  methods: {
    clearSinglePostData: function clearSinglePostData() {
      this.post = {
        title: {
          rendered: ''
        },
        content: {
          rendered: ''
        },
        date: ''
      };
    },
    getSinglePostData: function getSinglePostData() {
      var _this = this;

      this.clearSinglePostData();
      _vue2.default.http.get('/wp-json/wp/v2/posts/' + this.$route.params.post_id).then(function (request) {
        _this.post = request.data;
      }, function (request) {
        console.log(request);
      });
    }
  },
  created: function created() {
    this.getSinglePostData();
  }
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "\n<div class=\"container\">\n  <div class=\"row\">\n    <div class=\"col-xs-12\">\n      <h2>{{ post.title.rendered == '' ? 'Loading...' : post.title.rendered }}</h2>\n      <h4>{{ post.date }}</h4>\n      {{{ post.content.rendered }}}\n      <br>\n      <a v-link=\"{ path: '/' }\">Back ←</a>\n    </div>\n  </div>\n</div>\n"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  if (!module.hot.data) {
    hotAPI.createRecord("_v-1d09fc9e", module.exports)
  } else {
    hotAPI.update("_v-1d09fc9e", module.exports, (typeof module.exports === "function" ? module.exports.options : module.exports).template)
  }
})()}