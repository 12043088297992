'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _vue = require('vue');

var _vue2 = _interopRequireDefault(_vue);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  data: function data() {
    return {
      posts: []
    };
  },

  methods: {
    getPostData: function getPostData() {
      var _this = this;

      _vue2.default.http.get(window.app.env.url + '/wp-json/wp/v2/posts').then(function (request) {
        _this.posts = request.data;
      }, function (request) {
        console.log(request);
      });
    }
  },
  created: function created() {
    this.getPostData();
  }
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "\n<div class=\"container\">\n  <div class=\"row\" v-if=\"posts.length == 0\">\n    <div class=\"col-xs-12\">\n      <h1></h1>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-xs-6 well\" v-for=\"post in posts\" v-else=\"\">\n      <a v-link=\"{ path: `post/${post.id}` }\">\n        <h2>\n          {{ post.title.rendered }}\n        </h2>\n      </a>\n      {{{ post.content.rendered }}}\n    </div>\n  </div>\n</div>\n"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  if (!module.hot.data) {
    hotAPI.createRecord("_v-415d6478", module.exports)
  } else {
    hotAPI.update("_v-415d6478", module.exports, (typeof module.exports === "function" ? module.exports.options : module.exports).template)
  }
})()}